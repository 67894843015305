.d-flex {
  display: flex;
}

.lc-margin-top-sm {
  margin-top: 15px;
}

.lc-margin-top-md {
  margin-top: 30px;
}

.lc-margin-top-lg {
  margin-top: 40px;
}

.lc-content-padding {
  --padding-top: 30px;
}

.lc-justify-center {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lc-justify-center >div {
  min-height: 400px;
}

.lch-no-border {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.lch-circle {
  border-radius: 50%;
}


