.referral-page-container {
  ion-content {
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-bottom: 0px;
  }
}


.banner-container {
  background-color: #f7f6ff;
  align-items: center;
  text-align: center;
  justify-content: center;
  -webkit-box-shadow: -0.5em 0px 2.5em -0.5em rgba(0, 0, 0, 50%), -0.5em 0px 2.5em -0.5em rgba(0, 0, 0, 50%),
    inset -0.5em 0px 2.5em -0.5em rgba(0, 0, 0, 50%);
  box-shadow: -0.5em 0px 2.5em -0.5em rgba(0, 0, 0, 50%), -0.5em 0px 2.5em -0.5em rgba(0, 0, 0, 50%),
    inset -0.5em 0px 2.5em -0.5em rgba(0, 0, 0, 0);

  h3 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #230870;
  }

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #322f3c;
  }
}

.inner-scroll {
  padding-left: unset;
  padding-right: unset;
  padding-inline-start: var(--padding-start);
  padding-inline-end: var(--padding-end);
  padding: 0px !important;
}

.no-padding {
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-bottom: 4px;
}