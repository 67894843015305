@import '../../../../../theme/rebrand.scss';

.HRA-done-content {
  .HRA-block-center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 26px;
    left: 0;
    background: $success-color;
    width: 100%;
    min-height: 225px;
    padding: 18px;
  }

  .HRA-done-text {
    color: white;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  .HRA-img-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 42px;
    padding: 0 18px;
    top: 170px;
    left: 0;
    width: 100%;

    .HRA-next-step {
      text-align: center;
      width: 100%;
      background: #F7F7FF;
      padding: 26px 32px;
      color: #230870;
      font-size: 16px;
      line-height: 19px;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
    }
  }

  .HRA-done-img {
    width: 390px;
  }
}

.HRA-done-footer {
  background: transparent;
  box-shadow: none;
  border-radius: 0px;
  text-align: center;
  border: none;
}

.HRA-done-button {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.147727);
  border-radius: 20px;
}