.title-container-verify-Code {
  margin-top: 35px;

  h4 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #230870;
  }
}

.sub-title-container-verify-code {
  text-align: center;
  margin-bottom: -15px;

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #230870;
  }
}

.lch-edit-number-button {
  --border-color: #230870;
  --color: #230870;
  --border-style: solid;
  --border-width: 1px;
  box-sizing: border-box;
  --border-radius: 20px;
  --background: transparent;
  --background-activated: transparent;
}

.styles_react-code-input-container__tpiKG {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  padding: 0px;
  width: 312px !important;

  .styles_react-code-input__CRulA {
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 15px;

    input {
      border: 1px solid #eeedf9;
      box-sizing: border-box;
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.147727);
      border-radius: 8px;
      margin-left: 1px;
      margin-right: 1px;
      caret-color: #230870;
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }
  }
}

input {
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  background-color: white;
}

.buttons-container {
  max-width: 80px;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
}