@import './../../../../styles/ion-footer.scss';

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ion-button {
    width: 100%;

    + ion-button.button-block {
      margin: 0 0 0 20px;
    }

    ion-icon {
      font-size: 27px;
    }
  }
}
