.center-container {
  align-items: center;
  overflow-x: hidden;
  justify-content: center;
  height: 100%;
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  .share-topic-of-the-month-page-container {
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;
    ::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
    .header-logo {
      padding: 5px;
      margin-top: 20px;
    }
    .header-title {
      margin-bottom: 15px;
      h5 {
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #230870;
      }
    }
    .share-topic-reward-image {
      margin-bottom: 10px;
    }
    .share-topic-reward-image-summary {
      p {
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #230870;
      }
    }
    .footer-share-topic {
      box-shadow: none !important;
    }
  }
}

.share-topic-of-the-month-button-page {
  --border-color: #ffc833;
  --color: #ffffff;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 20px;
  --background: #ffc833;
  --background-activated: #ffc833;
  --box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.147727);
  width: 340px;
  height: 50px;
}

.share-topic-of-the-month-button-page-limit-reached {
  --border-color: #230870;
  --color: #ffffff;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 20px;
  --background: #230870;
  --background-activated: #230870;
  --box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.147727);
  width: 340px;
  height: 50px;
}
.share-topic-icon {
  margin-right: 10px;
}

.lc-footer-styles,
ion-footer,
.lc-footer,
footer {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
  border-radius: 0px;
  padding: 10px;
}
ion-button.button-small {
  width: 340px;
  max-width: 340px;
}

.footer-counter {
  text-align: center;
  margin-bottom: -5px;
  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 400px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.responsive-center-container {
  @media only screen and (min-width: 355px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.limit-reached-notification-banner {
  background-color: #eeedf9;
  color: #655dc6;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.button-width {
  width: 346px;
  max-width: 600px;
}
