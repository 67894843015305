.lc-terms {
  &-title {
    margin: 60px 0 25px;
  }

  .lc-scrolling-box {
    overflow-y: auto;
    height: calc(100% - 119px);
    box-shadow: inset 0 -10px 10px -10px #ffffff;

    ion-text {
      padding: 0 25px 10px;
    }
  }
}
