@import './../../styles/text.scss';
@import './../../theme/rebrand.scss';

.lc-request-appointment-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-right: 20px;
  margin-top: 64px;
  margin-left: 20px;
}

ion-toast {
  z-index: 30000;
}

.text-box {
  border: 1px solid $tertiary-color;
  transition: border-color 0.4s ease-out;
  border-radius: 8px;
  padding-left: 14px;
  min-height: 80px;
  min-width: 315px;
  margin-bottom: 25px;
  margin-top: 10px;
}