.lc-dob-verification-link-subtitle {
  margin: 20px 0;
}

.lc-dob-toast::part(container) {
  background: #E6B543;
  border:none;
  border-radius: 30px
}

.lc-dob-toast {
  --border-radius: 30px;
  text-align: center;
  color: black;
}