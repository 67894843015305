.lc-home-card-step {
  ion-card-header {
    background-color: #ffc833;
    background-image: url(../../../../../assets/img/rewards-empty.svg),
      url(../../../../../assets/img/rewards-card-empty.png);
    background-repeat: no-repeat, no-repeat;
    background-size: 127px, 159px;
    height: 77px;
    max-height: 77px;
    padding-left: 104px;
    padding-top: 18px;

    h5 {
      font-weight: 700;
      margin-right: 15px;
      color: #230870;
      font-size: 29px;
    }

    img {
      width: 115px;
      height: 77px;
      margin-left: 10px;
      overflow: hidden;
    }
  }
}

.lc-home-card-step-no-reward {
  ion-card-header {
    background-image: url(../../../../../assets/img/empty-reward-card-banner.svg);
    background-color: #E8E0FE;
    background-repeat: no-repeat, no-repeat;
    background-size: contain;
    background-position: center;
    height: 77px;
    max-height: 77px;
    padding-left: 104px;
    padding-top: 18px;

    h5 {
      font-weight: 700;
      margin-right: 15px;
      color: #230870;
      font-size: 29px;
    }

    img {
      width: 115px;
      height: 77px;
      margin-left: 10px;
      overflow: hidden;
    }
  }
}
ion-modal.lc-modal-backdrop.lc-modal-request {
  ion-card {
    transform: translateY(calc(50% - 80px));
  }
  ion-card-header {
    padding: 20px 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      text-align: left;
    }

    ion-button {
      height: 23px;
      font-size: 15px;
    }
  }

  ion-card-content {
    padding: 10px 20px 20px;

    form {
      width: 100%;
    }

    ion-button {
      font-size: 15px;
      width: 100%;
      height: 52px;
      padding: 0;
      border-left: 0;
    }
  }
}
