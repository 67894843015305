@import '../../theme/colors.scss';

.graph-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graph-view {
  width: 100%;
  max-width: 400px;
}

h5 {
  text-align: center;
  color: #{$rebrand-gray-300};
}