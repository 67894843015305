h5 {
  font-family: Raleway;
  font-style: normal;
  font-weight: bolder;
  font-size: 18px;
  line-height: 21px;
}

.label-icon {
  margin-bottom: 10px;
  margin-right: 3px;
}

ion-checkbox {
  --size: 24px;
}

.ion-color-tertiary {
  border-radius: 15px;
}

.check-box {
  margin-left: 25px;
  margin-right: 10px;
}

.sms-notifications-label {
  padding-left: -25px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.sms-notifications {
  padding: 16px 25px;
}

.tablet-container {
  @media (min-width: 481px) and (max-width: 1024px) {
    justify-content: center;
    align-items: center;
  }
}

.responsive-center-container {
  @media only screen and (min-width: 355px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.save-button-account-settings {
  width: 360px;
}

.lc-acc-settings-container {
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
