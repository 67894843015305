ion-item {
  --background: transparent;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-end: 0;
  --padding-start: 0;
  --highlight-color-focused: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
  --color-activated: transparent;
  --color-focused: transparent;
  --color-hover: transaprent;
}
