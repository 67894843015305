.lc-journey-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  .lc-card-vertical-align {
    width: 600px;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    margin-left: 105px;
  }
}