.checkIn-header-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;

  .checkIn-header-title-container {
    margin-top: -3rem;
    margin-bottom: -1.5rem;
    h1 {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
    }
  }
}
.checkIn-header-subtitle-container {
  min-width: 150px;
  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
  }
  svg {
    margin: 5px 10px 0px 0px;
  }
}

.checkIn-reward-banner {
  width: 375px;
  max-width: 385px;
  background-color: #fff4d7;
  border-radius: 10px;
  padding: 25px 35px 15px 0px;
  margin-bottom: 3.5rem;
  @media (min-width: 270px) and (max-width: 295px) {
    width: 260px;
    text-align: center;
  }
  @media (min-width: 360px) and (max-width: 365px) {
    width: 330px;
    text-align: center;
  }
  @media (min-width: 370px) and (max-width: 390px) {
    width: 355px;
  }
  @media (min-width: 374px) and (max-width: 375px) {
    width: 345px;
  }
  @media (min-width: 410px) and (max-width: 425px) {
    width: 380px;
  }
  @media (min-width: 600px) {
    width: 445px;
  }
  p {
    color: #230870;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  svg {
    margin: 0px 25px -3px 0px;
  }
}

.checkIn-next-journey-step-footer {
  margin-top: 40px;
  text-align: center;
  p {
    color: rgba(35, 8, 112, 1);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
  }
}

.lch-footer-check-in {
  background: #eeedf9;
  box-shadow: none;
  border-color: #eeedf9;
  border-radius: 0px;
  background-color: #eeedf9;
}

.checkIn-next-journey-step-card-container {
  text-align: left;
  margin: -10px 15px 10px 15px;
  @media (min-width: 600px) {
    margin-right: auto;
    margin-left: auto;
  }
}

.lch-check-in-card {
  margin-top: -42px;
  z-index: 10;
  border-radius: 10px;
}

.lch-ion-item-check-in {
  #purple-splash {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: -1;
  }
}

.lch-ion-content-check-in {
  --padding-end: 0px;
  --padding-start: 0px;
}

.checkIn-card-center-container {
  @media (min-width: 600px) {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    margin-top: 15px;
  }
  @media (min-width: 431px) and (max-width: 599px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
}
