.lc-phone-verify-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 75px;
}

.lc-phone-verify-sub-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: flex-end
}

.lc-phone-verify-title {
  text-align: center;
}

.lc-phone-verify-inputs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px
}

.lc-phone-verify-input {
  width: 50px;
  text-align: center;
}