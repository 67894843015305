.card-container {
  margin-bottom: -85px;
}

.lch-topic-of-the-month {
  h5 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #230870;
  }

  .summary {
    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      height: 70px;
      line-height: 120%;
      position: relative;
      overflow: hidden;
    }
  }
}

.share-topic-of-the-month-container {
  width: 100%;
  border-top: solid;
  border-color: #e0dfe409;
  background-color: #fff4d7;
  border-radius: 0px 0px 15px 15px;
  padding: 10px;
  display: flex;
  align-content: space-between;
  align-items: center;
  justify-content: center;

  .label {
    margin-left: 10px;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #230870;
    }

    .reward-amount {
      font-family: Raleway;
      font-style: normal;
      font-size: 18px;
      line-height: 16px;
      color: #230870;
      margin-bottom: 1px;

      a {
        text-decoration: none;
        font-size: 14px;
      }
    }
  }

  .share-button {
    margin-left: 31px;
  }

  .share-topic-icon {
    margin-right: 10px;
  }

  .share-topic-of-the-month-button {
    --color: #ffffff;
    --border-radius: 20px;
    --background: #ffc833;
    --background-activated: #ffc833;
    --box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.147727);
    width: 132px;
    height: 50px;
  }

  span {
    margin-left: 8px;
  }
}

.container {
  max-width: 448px;
  width: 100%;

  @media only screen and (min-width: 540px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}