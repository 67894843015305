@import './../../../styles/text.scss';

.lc-impact {
  h5 {
    margin: 25px 0 30px 0;
  }

  p {
    font-size: 16px;
  }

  h3 {
    @extend h1;
    margin: 0;
  }

  &-circles {
    margin-top: 8px;
  }

  &-circles-graph {
    position: relative;
    width: 244px;
    height: 244px;
    background: #c1bee8;
    border-radius: 50%;
    margin: 15px auto;

    div {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: #9c97da;
      width: 154px;
      height: 154px;
      border-radius: 50%;

      div {
        background: #655dc6;
        width: 90px;
        height: 90px;
        top: 10px;
      }
    }

    ion-text {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      color: white;

      h4 {
        font-size: 16px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
      }
    }
  }

  &-circles-graph2 {
    ion-text {
      bottom: 4px;
    }
  }

  &-circles-graph3 {
    ion-text {
      bottom: 20px;
    }
  }
}

.lc-impact-buttons-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.block-center-impact {
  max-width: 600px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.impact-card-container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
