@import '../../styles/text.scss';
@import '../../theme/rebrand.scss';

.calendar {
  &-container {
    width: 100%;
    height: 380px;
    background: white;
    font-weight: 600;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
    text-align: center;

    // calendar borders
    &-days {
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      background: gray;
      border: 2px solid gray;
    }
  }

  &-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    user-select: none;



    &-disabled {
      background: #dddedf;
      cursor: not-allowed;
    }

    &-clickable {
      background: white;
      cursor: pointer;

      &:hover {
        background: #efefef;
      }

      &:active:hover {
        background: #cfcfcf;
      }
    }

    &-confirmed {
      background: #77D4A7;
    }

    &-unconfirmed {
      background: #FFD200;
    }

    &-today {
      color: white;
      background: #42384d;

      &:hover {
        background: #645672;
      }
    }
  }

  &-current-month {
    grid-column: span 5 / span 5;
  }
}