// Blue
$rebrand-blue-50: #F4FBFE;
$rebrand-blue-100: #D7F0FD;
$rebrand-blue-500: #30B3F1;
$rebrand-blue-700: #5B727E;
$rebrand-blue-800: #37444B;
$rebrand-blue-base: #B6E4FB;

// Purple
$rebrand-purple-50: #F9F4FF;
$rebrand-purple-100: #E9D3FF;
$rebrand-purple-600: #C4A4E6;
$rebrand-purple-700: #625273;
$rebrand-purple-800: #41374D;
$rebrand-purple-base: #DAB6FF;

// Green
$rebrand-green-50: #F4FCF6;
$rebrand-green-100: #DAF4E1;
$rebrand-green-700: #2C6B3E;
$rebrand-green-base: #55CD77;

// Yellow
$rebrand-yellow-50: #A68230;
$rebrand-yellow-100: #FFECC0;
$rebrand-yellow-700: #E6B543;
$rebrand-yellow-base: #FFC84A;

// B/W
$rebrand-gray-50: #E9EAEB;
$rebrand-gray-100: #DDDEDF;
$rebrand-gray-200: #878B92;
$rebrand-gray-300: #454C57;
$rebrand-gray-base: #242C39;

// Navy
$rebrand-navy: #242C39;