.next-activity-section {
  border-top: 1px solid #eeedf9;
  padding: 10px 0px 15px 17px;
  width: 100%;
  p {
    margin-top: 10px;
    color: #230870;
    font-weight: bold;
    font-size: 16px;
  }
}
.reward-badge-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}
.lch-ion-card-content-check-in {
  margin-top: -25px;
  margin-bottom: 10px;
}
