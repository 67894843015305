.representative-users-content {
  background-color: #EEEDF9;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #655DC6;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .list-container {
    background: white;
    overflow: scroll;
    box-shadow: 0px 2px 2px rgba(101, 93, 198, 0.12), 0px 2px 24px rgba(101, 93, 198, 0.2);
    border-radius: 15px;
    max-height: calc(100% - 54px);

    .row {
      width: 100%;
      position: relative;
      white-space: nowrap;
      padding: 0.5rem 1rem;

      @media screen and (min-width: 720px) {
        width: 60%;
      }
    }

    .list-item {
      display: inline-block;
      vertical-align: top;
      white-space: break-spaces;
      width: 50%;
    }

    h2 {
      text-align: left;
      margin: 0;
      padding-bottom: 8px;
      font-size: 12px;
      line-height: 14px;
      border-bottom: 1px solid #EEEDF9;
    }

    h3 {
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      margin-bottom: 6px;
      color: #230870;
    }

    h4 {
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #230870;
      margin: 0;
      margin-bottom: 6px;
    }

    h5 {
      background: #EEEDF9;
      border-radius: 5px;
      padding: 5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #040031;
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #65616F;
    }

    .next-step {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #040031;
    }


  }
}

.representative-users-footer {
  background: transparent;
  box-shadow: none;
  border-radius: 0px;
  text-align: center;
  border: none;
}