.representative-block-center {
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.representative-login-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #655dc6;
}

.representative-login-sub-title {
  p {
    text-align: center;
  }
}

#splash-icon {
  position: absolute;
  z-index: -100;
  top: 0;
  left: -5px;
}

.representative-login-footer {
  background: #ffffff;
  box-shadow: none;
  border-radius: 0px;
  padding: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #322f3c;
}