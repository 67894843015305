.reward-badge {
  display: flex;
  height: 21px;
  background: #ffc833;
  border-radius: 50px;
  color: #230870;
  margin: 20px 0px 0px 15px;
  padding: 10px 5px 5px 5px;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-left: 2px;
    padding-right: 3px;
  }
  svg {
    margin: 7px 5px -3px 0px;
    padding: 0px 0px 0px 0px;
  }
}
