ion-content {
  --padding-start: 17px;
  --padding-end: 17px;
  --padding-bottom: 30px;

  &.lc-content-footer {
    --padding-bottom: 120px;
  }

  &.lc-content-footer-with-tabs {
    --padding-bottom: 153px;
  }
}

ion-content.lc-content-modal {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
