@import './../../../theme/rebrand.scss';
@import './../../../styles/text.scss';

.lc-learning-center-topic-of-the-month-banner {
  ion-item {
    margin: -20px -17px 20px -17px;

    ion-text {
      padding: 30px 25px;
    }

    ion-icon {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
  }

  article {
    padding: 50px 17px 75px;
    color: #fff;
    width: 100%;

    h4 {
      font-weight: 700;
    }
  }

  ion-card {
    border-radius: 15px;
    padding: 40px 20px 20px;
    margin: -26px 15px 0 15px;
    z-index: 1;
  }

  ion-button {
    width: 100%;
  }

  ion-icon {
    font-size: 20px;
    margin: 10px 0;
  }

  footer {
    display: block;
  }
}

.learning-center {
  .header-container {
    height: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 65px;
  }

  .icon {
    align-self: center;
    margin-top: -15px;
    margin-right: 9px;
  }

  .title {
    align-self: center;
  }

  ion-card {
    margin: -130px 0px 0px 0px;
    padding-top: 30px;
    border-radius: 15px 15px 0px 0px;
    margin-bottom: 0px;
  }

  h4 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  .topic-of-the-month-container {
    @media only screen and (min-width: 540px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.lc-card-about {
  margin-top: 35px;

  h4 {
    font-weight: 700;
    margin: 0 0 0 10px;
  }

  ion-card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 25px;
    border-bottom: 2px solid #eeedf9;

    ion-icon {
      fill: #655dc6;
      width: 20px;
    }
  }

  ion-card-content {
    padding: 0;

    ion-item {
      position: relative;
      border-bottom: 1px solid #eeedf9;
    }

    ion-text {
      padding: 15px 55px 20px 25px;
    }

    h5 {
      font-weight: 700;
      margin-bottom: 4px;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-button {
    position: absolute;
    width: 37px;
    right: 0;
    top: 0;
    bottom: 0;
    background: #f7f6ff;

    ion-icon {
      width: 17px;
      height: 100%;
      padding: 0 10px;
      color: $primary-color;
    }
  }
}

.lc-articles-container {
  @media only screen and (min-width: 540px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .lc-card-vertical-align {
      width: 600px;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      margin-left: 105px;
    }
  }
}