@import '../theme/rebrand.scss';

ion-button {
  --border-radius: 20px;
  --box-shadow: 0;
  height: 56px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;

  &.button-disabled {
    opacity: 1;
  }

  +ion-button.button-block {
    margin: 15px 0 0;
  }

  &.button-small {
    width: calc(50% - 5px);
    display: inline-block;
    margin: 0 5px 0 0;

    +.button-small {
      margin: 0 0 0 5px;
    }
  }
}