.slide-layout {
  display: block;
  width: 100%;
  height: 90vh;
  align-items: center;
  overflow-x: hidden;
  justify-content: center;
  .slide-logo-title-container {
    position: relative;
  }
  .slide-illustration-container {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .slide-title-container {
    margin-top: 5px;
    padding: 10px;
  }
  .slide-sub-title-container {
    margin-top: 10px;
    color: rgba(50, 47, 60, 1);
    margin-bottom: 10px;
  }
  .slide-items-container {
    text-align: left;
    color: rgba(50, 47, 60, 1);
    font-size: 15px;
    line-height: 18px;
    ion-item {
      --padding-top: 10px;
      --padding-bottom: 10px;
    }
    svg {
      margin-right: 15px;
      margin-left: 10px;
    }
    @media (min-width: 481px) {
      max-width: fit-content;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.swiper-pagination {
  z-index: 1;
}

.slide-buttons-container {
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}

.review-swiper-button-next {
  z-index: 3;
  --border-color: transparent;
  --color: $primary;
  --border-style: none;
  --border-width: 1px;
  --border-radius: none;
  --background: transparent;
  --background-activated: $tertiary;
  --box-shadow: none;
  --background-hover: none;
}
.review-swiper-button-prev {
  z-index: 3;
  --border-color: transparent;
  --color: $primary;
  --border-style: none;
  --border-width: 1px;
  --border-radius: none;
  --background: transparent;
  --background-activated: $tertiary;
  --box-shadow: none;
  --background-hover: none;
}

.slide-two-title-container {
  margin-top: 8rem;
  margin-bottom: 3.1rem;
}
.slide-two-video-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}
