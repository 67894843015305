@import './../../../styles/text.scss';
@import './../../../theme/rebrand.scss';
@import './../../../theme/colors.scss';

.lc-biometrics {
  .lc-empty {
    height: calc(var(--vh, 1vh) * 100 - 187px);
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

  }

  &-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    padding-bottom: 120px;
  }

  /* Add biometrics form layout
  This helps with form scrolling when virtual keyboard is opened on mobile
  */
  &-form {
    padding: 16px;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 90px;
    left: 0;
    right: 0;
  }

  /* Add Biometrics */

  .save-button-biometrics {
    width: 360px;
    height: 50px;
  }

  .lc-biometrics-container {
    @media only screen and (min-width: 600px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-modal {
    .lc-modal-header {
      border-bottom: 1px solid #e0dfe4;
      padding: 0px 17px;
      flex: 0 0 auto;
    }

    ion-list {
      padding: 30px 15px 0;
      flex: 0 1 auto;
      overflow-y: auto;
    }

    ion-text {
      margin-left: 5px;
    }

    h4 {
      margin-bottom: 0;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h4 {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  &-logged-section {
    overflow-y: auto;
    height: 100%;
  }

  &-logged-date {
    border-radius: 15px;
    margin-right: 3px;

    ion-text {
      padding: 15px 10px;
    }

    p {
      color: white;
      text-transform: uppercase;
    }
  }

  &-logged {
    &:first-child {
      margin-top: 25px;
    }
  }

  &-logged-data {

    h5,
    .card-content-ios h5 {
      font-weight: 700;
      margin-bottom: 15px;
    }

    ion-icon {
      font-size: 15px;
    }

    h2 {
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 700;
      line-height: 18px;
    }
  }

  .card-content-md p+p {
    font-weight: 700;
  }

  &-pressure-modal {
    img {
      transform: translateY(50%);
    }
  }

  .blood-pressure-data {
    background-image: url('../../../assets/img/blood-pressure.png');
  }
}

.error-span {
  margin-top: 20px;
}

.biometrics-container {
  @media only screen and (min-width: 600px) {
    max-width: 460px;
    margin-right: auto;
    margin-left: auto;
  }
}

.lc-form-group-biometrics {
  position: relative;

  /* Error message */
  span {
    color: $danger-color;
    font-size: 12px;
    margin-top: -15px;
    margin-bottom: 10px;
  }

  ion-select {
    color: $primary-color;
  }

  ion-datetime {
    color: $primary-color;
  }

  .lc-input-password span {
    top: 57px;
  }

  /* error styles for label and input */
  &-error {

    ion-input,
    ion-select {

      input,
      .native-input.sc-ion-input-md,
      .native-input.sc-ion-input-ios {
        border: 1px solid $danger-color;
      }
    }

    ion-datetime,
    .lc-input {
      border: 1px solid $danger-color;
    }
  }

  // Disabled styles
  &.item-interactive-disabled {
    ion-label.label-stacked {
      opacity: 1;
    }

    ion-input {
      --background: #f2f2f2;
    }
  }
}

.data-color-high-stage-1 {
  color: #f6ad40;
}

.data-color-high-stage-2 {
  color: #f17f3f;
}

.data-color-success {
  color: rgba(119, 212, 167, 1);
}

.data-color-danger {
  color: rgba(240, 105, 86, 1);
}

.data-color-elevated {
  color: #ffc833;
}

.log-biometrics-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;

  ion-card {
    padding: 16px;
    max-width: 400px;
  }
}


.fields-section {
  display: flex;
  flex-direction: column;

  ion-label {
    font-size: 12px;
  }

  >div {
    display: flex;
    gap: 16px;

    @media only screen and (max-width: 420px) {
      width: 95%;
    }

    section {
      width: 50%;
    }
  }
}

.fields-sistolic-diastolic {
  display: flex;
  width: 100%;
  gap: 16px;



  >hr {
    width: 3px;
    height: 50%;
    background: black;
    margin: 24px 12px 0px;
    transform: rotate(10deg);
    border-radius: 12px;
  }
}

.find-doctor-card {
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.bp-info-modal .modal-wrapper {
  width: 100%;
  height: 100%;
}

.bp-chart-link {
  text-align: center;
  font-size: 16px;
  margin-top: 8px;
}

.icon-container{
  display: flex;
  width: 100%;
  justify-content: center;
}

.icon {
  font-size: 100px;
  color: #{$rebrand-green-base};
}

.confirmation-page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.confirmation-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  max-width: 400px;
}