.lc-verify-code-confirmation {
  ion-item {
    margin: 15px -17px 20px -17px;

    ion-text {
      padding: 30px 25px;
    }

    ion-icon {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
  }

  article {
    padding: 50px 17px 75px;
    color: #fff;
    width: 100%;

    h4 {
      font-weight: bold;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }
  }

  ion-card {
    border-radius: 15px;
    padding: 40px 20px 20px;
    margin: -70px 15px 0 15px;
    z-index: 1;
  }

  ion-button {
    width: 100%;
  }

  ion-icon {
    font-size: 20px;
    margin: 10px 0;
  }

  footer {
    display: block;
  }
}

.lc-cellphone-confirmation-buttons-container {
  display: flex;
  justify-content: center;
}