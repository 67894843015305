@import '../theme/rebrand.scss';

ion-alert.select-alert,
.sc-ion-alert-md-h.select-alert,
.sc-ion-alert-ios-h.select-alert {
  --max-width: 500px;
  --width: 95%;
  --background: #ffffff;

  .alert-wrapper {
    border-radius: 25px;
    padding: 0 15px;
  }

  .alert-head {
    padding-top: 25px;

    .alert-title {
      font-size: 16px;
    }
  }

  .alert-radio-group {
    max-height: 400px;
  }

  .alert-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .alert-button {
    background: $primary-color;
    height: 56px;
    min-width: calc(50% - 15px);
    border-radius: 20px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    text-align: center;
    text-transform: capitalize;
    margin: 15px 7.5px 15px 0;
    flex: calc(50% - 15px);

    span.alert-button-inner {
      justify-content: center;
    }

    +.alert-button {
      margin: 15px 0 15px 7.5px;
    }
  }

  .alert-button-role-cancel {
    border: 2px solid $primary-color;
    background: white;
    color: $primary-color;
  }
}