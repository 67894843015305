.lc-confirmation-earnings-tab {
  .message-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ion-item {
    margin: 15px -17px 20px -17px;

    ion-text {
      padding: 30px 25px;
    }

    ion-icon {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
  }

  article {
    padding: 50px 17px 75px;
    color: #fff;
    width: 100%;

    h4 {
      font-weight: 700;
    }
  }

  ion-card {
    border-radius: 15px;
    padding: 40px 20px 20px;
    margin: -70px 20px 0px;
    z-index: 1;
    overflow: visible;

    h5 {
      font-weight: 400;
    }
  }

  .coin {
    $coinSize: 55px;
    margin-top: -$coinSize * 1.25;

    span {
      display: block;
      position: relative;
      width: $coinSize;
      height: $coinSize;
      border-radius: 50%;
      background: #ffc833;
      border: 1px solid #fff;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      line-height: 50px;
      z-index: 2;
      margin: 0 auto;
      align-items: center;
      outline: 3px solid #ffc833;
    }
  }

  ion-button {
    width: 100%;
  }

  ion-icon {
    font-size: 20px;
    margin: 10px 0;
  }

  footer {
    display: block;
  }
}