ion-menu {
  --width: 100%;

  ion-header {
    ion-toolbar {
      position: relative;
      --min-height: 77px;
      --background: url(/assets/img/menu-bg.png) bottom left no-repeat;
      --border-color: transparent;
      background-color: #eeedf9;
      border-radius: 0;
    }

    ion-title {
      padding-right: 57px;
      padding-left: 30px;
      line-height: 24px;

      span {
        font-weight: 400;
      }
    }

    ion-icon {
      position: absolute;
      top: 27px;
      right: 26px;
      font-size: 25px;
    }
  }

  ion-item {
    --border-color: #f1f1f1;
    --min-height: 54px;
    --inner-padding-end: 20px;
    --inner-padding-start: 25px;
  }

  ion-text {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
      margin-left: 15px;
      font-weight: 600;
    }

    ion-icon {
      font-size: 20px;
    }
  }
}

.logout-pointer {
  cursor: pointer;
}