@import '../../theme/colors.scss';

.top-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 16px;

  div {
    width: 100%;
    background: #{$rebrand-purple-50};
    padding: 16px;
    border-radius: 24px;
    font-weight: 700;
    font-size: 20px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      max-width: none;
    }
  }

  ion-text {
    font-size: 14px;
    display: block;
    text-align: start;
    margin-top: 8px;
  }
}