.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.content {
  max-width: 400px;
  padding-bottom: 100px;
}

.content-chart {
  max-width: 1024px;

  @media only screen and (max-width: 1096px) {
    width: 100%;
  }
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  scroll-behavior: smooth;

  @media only screen and (max-width: 1024px) {
    align-items: flex-start;
  }
}

.foot-note {
  width: 100%;
  text-align: end;
  margin-bottom: 16px;
}