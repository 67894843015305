.representative-home-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 25px;
}

.representative-home-subtitle {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #230870;
    text-align: center;
  }
}

.representative-home-footer {
  background: transparent;
  box-shadow: none;
  border-radius: 0px;
  text-align: center;
  border: none;
}

.representative-home-button {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.147727);
  border-radius: 20px;
}

.representative-home-Illustration {
  align-items: center;
  justify-content: center;

  svg {
    margin: 0px auto;
    display: block;
  }
}