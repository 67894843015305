.lc-expired-link-button {
  margin-top: 100px;
}

.lc-get-new-link-subtitle {
  margin: 20px 0;
}

.lc-footer-asset {
  margin-top: 40px;
  // margin-left: -20px;

  img {
    width: 100%;
  }

  @media only screen and (min-width: 500px) {
    margin-top: 300px;
  }
}