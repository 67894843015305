.lc-confirmation {
  ion-item {
    margin: -20px -17px 20px -17px;

    ion-text {
      padding: 30px 25px;
    }

    ion-icon {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
  }

  article {
    padding: 50px 17px 75px;
    color: #fff;
    width: 100%;

    h4 {
      font-weight: 700;
    }
  }

  ion-card {
    border-radius: 15px;
    padding: 40px 20px 20px;
    margin: -26px 15px 0 15px;
    z-index: 1;
  }

  &-coin {
    margin-top: -69px;

    span {
      display: block;
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #ffc833;
      border: 1px solid #fff;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      line-height: 50px;
      z-index: 2;
      margin: 0 auto;

      &:before {
        content: '';
        position: absolute;
        top: -3px;
        left: -3px;
        width: 50px;
        height: 50px;
        z-index: 1;
        border: 2px solid #ffc833;
        border-radius: 50%;
      }
    }
  }

  ion-button {
    width: 100%;
  }

  ion-icon {
    font-size: 20px;
    margin: 10px 0;
  }

  footer {
    display: block;
  }
}
