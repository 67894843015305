.lc-empty-section {
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 15px;

  h5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  img {
    margin-bottom: 20px;
  }
}
