@import '../../../theme/colors.scss';


ion-tabs {
  background: #{$rebrand-gray-50};

  >div {
    position: unset;
    contain: none;
  }
}

.custom-tab-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 6px;
}

.custom-tab-icon {
  font-size: 24px;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
}

.custom-tab-rounded {
  position: absolute;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  bottom: 0;

  height: 100px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
    height: 60px;
  }
}