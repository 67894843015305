.save-button-login {
  width: 360px;
  height: 50px;
}

.lc-login-container {
  @media only screen and (min-width: 600px) {
    align-items: center;
    overflow-x: hidden;
    justify-content: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    ::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

.lc-login-buttons-container {
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block-center {
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
