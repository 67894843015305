.lc-modal {
  &-wrapper {
    text-align: center;
  }

  &-header {
    padding: 5px 20px 3px;

    h3 {
      margin: 0;
    }

    ion-button {
      height: inherit;
      font-size: 14px;
    }
  }
}

ion-modal.lc-modal-backdrop {
  --background: rgba(0, 0, 0, 0.3);

  section {
    margin: 0 17px;
  }

  /*use this class when there's no close button on the modal*/
  .lc-modal-backdrop-close {
    min-height: 100vh;
    padding: 0 17px;
  }

  ion-card {
    transform: translateY(calc(50% + 61px));
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
  }

  ion-card-header {
    padding: 30px 30px 10px;

    h4 {
      font-weight: 700;
      color: #322f3c;
    }

    p {
      color: #322f3c;
    }
  }

  ion-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-top: 1px solid #eeedf9;

    ion-router-link,
    ion-button {
      width: 50%;
      padding: 15px 3px;
      font-size: 14px;
    }

    ion-button {
      --bacground: #fff;
      height: 52px;
      border-left: 1px solid #eeedf9;
      margin: 0;
      --background-hover: #fff;
      --background-activated: #fff;
      --background-focused: #fff;
    }
  }
}

// lc-alert-modal, Emules the styles of an alert, like when clicking on a select
.lc-alert-modal.sc-ion-modal-md-h,
.lc-alert-modal.sc-ion-modal-ios-h {
  --height: 100% !important;
  --width: 100%;
  --backdrop-opacity: var(--ion-backdrop-opacity, 1);
}

.lc-alert-modal {
  .modal-wrapper {
    --max-width: 500px;
    --width: 95%;
    --background: #fff;
    --min-height: auto;
    --max-height: 60%;
    --min-height: auto;
    --border-radius: 25px;
  }

  &.sc-ion-modal-md-h:first-of-type {
    --backdrop-opacity: 1;
  }

  &-header {
    margin: 37px 20px 0 20px;
    padding-bottom: 23px;
    border-bottom: 1px solid var(--ion-color-medium);
  }

  ion-list {
    padding: 13px 0 80px;
  }

  ion-item {
    position: relative;
    --padding-top: 1px;
    --padding-bottom: 1px;
    --padding-start: 10px;
  }

  ion-label {
    font-weight: 400;
  }

  ion-radio {
    margin: 0 28px;
  }

  ion-footer {
    box-shadow: none;
    border-radius: 0;

    &:before {
      content: '';
      width: calc(100% - 40px);
      position: absolute;
      top: 0px;
      left: 20px;
      height: 1px;
      background: var(--ion-color-medium);
    }
  }

  /* Styles for ios needed to emulate the styles on the ion-select component */
  ion-radio.ios {
    order: 2;
  }
}
