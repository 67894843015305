@import '../theme/rebrand.scss';
@import '../theme/colors.scss';

.lc-full-spot-bg {
  --background: #{$rebrand-gray-50};
}

.lc-spot-with-logo {
  &-bg {

    h1 {
      margin: 20px 0;
    }
  }

  &-ptns {

    h1 {
      margin: 20px 0;
    }
  }

  &-image {
    max-width: 209px;
    margin: 50px auto 0;
  }
}

.lch-light-teal {
  background-color: #D7F0FD !important;
}
