body {
  font-variant-numeric: lining-nums;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 42.26px;
  margin: 100px 0 50px 0;
  text-align: center;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.18px;
  text-align: center;
}

h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 23.48px;
}

h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 12px;
  margin-right: 15px;
  color: #65616f;
  font-weight: 700;
  line-height: 15px;
  padding-bottom: 5px;
}

small {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #8f8b99;
  font-weight: 700;
  letter-spacing: 1.8px;
}

p,
.paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
}

li {
  @extend p;
}

ul,
ol {
  padding-left: 25px;
}

.lc-text-line {
  position: relative;
  margin: 30px 0 25px;
  text-align: center;
}

ion-text {
  width: 100%;
}

.lc-bold {
  font-weight: 700;
}

.align-start {
  text-align: start;
}

.lc-semi-bold {
  font-weight: 600;
}

hr {
  background: #eeedf9;
}

.lc-underline {
  text-decoration: underline;
}

ion-router-link {
  &:focus-visible {
    outline: 0;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    &:focus-visible {
      outline: 0;
    }
  }
}

.lch-member-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  line-height: 25px;
}