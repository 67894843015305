.register-profile {
  h2 {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.lc-profile-register-buttons-container {
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block-center-profile-register {
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.profile-register-footer-container {
  max-width: 600px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: center;
}

.save-button-profile-register {
  max-width: 600px;
  width: 350px;
}

.lc-profile-register-container {
  @media only screen and (min-width: 600px) {
    align-items: center;
    overflow-x: hidden;
    justify-content: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    ::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

.representative-questionnaire-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin-bottom: -90px;
}

.terms-check-box {
  margin-right: 10px;
  margin-bottom: -4px;
}

.terms-text {
  font-weight: normal;
}