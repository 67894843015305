@import './text.scss';

ion-header {
  &.header-md::after {
    background-image: none;
  }

  ion-toolbar {
    --min-height: 48px;
    box-shadow: 0px 2px 10px rgba(101, 93, 198, 0.25);
    border-radius: 0px 0px 20px 20px;
  }

  ion-title {
    @extend h3;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
  }

  ion-buttons.sc-ion-buttons-md-h + ion-title {
    padding-left: 0;
  }

  /* Back arrow button on header */
  ion-buttons.lc-back-arrow.sc-ion-buttons-ios-h {
    width: 15px;
    margin-left: 15px;

    + ion-title {
      padding-left: 47px;
    }
  }

  /* Back button just with icon*/
  .lc-back-arrow {
    ion-icon {
      font-size: 20px;
      margin-left: 18px;
    }

    + ion-title {
      padding-left: 10px;
    }

    &.ios + ion-title {
      padding-left: 50px;
    }
  }
}

ion-back-button {
  --min-width: 15px;
}
