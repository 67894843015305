@import '../theme/rebrand.scss';
@import '../theme/colors.scss';
@import './text.scss';

/* Input */
ion-input {
  --background: #ffffff;
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  min-height: 50px;
  font-size: 14px !important;
  border-radius: 8px;
  margin-bottom: 20px;

  input,
  .native-input.sc-ion-input-md,
  .native-input.sc-ion-input-ios {
    font-size: 14px !important;
    border: 1px solid #{$rebrand-gray-100};
    --border-radius: 8px;
    --background: #fff;
    --padding-end: 14px;
    --padding-start: 14px;
    --padding-bottom: 16px;
    --padding-top: 16px;
    transition: border-color 0.4s ease-in-out;

    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }
  }
}

.lc-input {
  @extend ion-input;
  width: 100%;
  padding: 14px;
  color: #242C39;
  border: 1px solid #{$rebrand-gray-100};
  transition: border-color 0.4s ease-in-out;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border: 1px solid $primary-color;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: $input-text-disabled;
    background-color: $input-background-disabled;
  }

  &-password {
    position: relative;
    width: 100%;
    margin-top: 9px;

    ion-icon {
      position: absolute;
      top: 13px;
      right: 10px;
      z-index: 5;
      font-size: 22px;
    }
  }
}

.item-label-stacked.sc-ion-input-ios-h,
.item-label-stacked .sc-ion-input-ios-h {
  --background: #fff;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-bottom: 0px;
  --padding-top: 0px;
}

.sc-ion-input-md-h {
  --background: #fff;
}

/* Textarea */
ion-textarea,
.sc-ion-textarea-md-h {
  @extend ion-input;
  min-height: 80px;

  .textarea-wrapper textarea,
  .native-textarea.sc-ion-textarea-ios {
    border: 1px solid #{$rebrand-gray-100};
    transition: border-color 0.4s ease-in-out;
    --border-radius: 8px;
    --padding-top: 14px;
    padding-left: 14px;
    min-height: 80px;

    &:focus {
      border: 1px solid $primary-color;
    }
  }
}

/* Datetime */
ion-datetime {
  @extend ion-input;
  background: url(/assets/img/calendar.svg) calc(100% - 15px) 13px no-repeat;
  background-color: #fff;
  border: 1px solid #{$rebrand-gray-100};
  transition: border-color 0.4s ease-in-out;
  --padding-end: 44px;
  --padding-start: 14px;
  line-height: 48px;
  height: 50px;

  &[aria-expanded='true'] {
    border: 1px solid $primary-color;
  }
}

/* Label */
ion-label,
.label-stacked.sc-ion-label-ios-h {
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;

  +ion-input {
    margin-top: 6px;
  }
}

/* Select */
ion-select {
  --placeholder-color: var(--ion-color-primary);
  --placeholder-opacity: 1;
  --padding-end: 14px;
  --padding-start: 14px;
  --padding-bottom: 14px;
  --padding-top: 14px;
  --color: var(--ion-color-primary);
  min-height: 50px;
  background: #fff;
  border: 1px solid #{$rebrand-gray-100};
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 6px;
  font-size: 14px;
  transition: border-color 0.4s ease-in-out;

  &::part(placeholder) {
    font-weight: 400;
    font-size: 14px;
  }

  &::part(icon) {
    color: $primary-color;
    opacity: 1;
  }

  &.select-expanded {
    border: 1px solid $primary-color;
  }
}

/* SearchBar */
ion-search-bar,
.sc-ion-searchbar-md-h,
.sc-ion-searchbar-ios-h {
  --background: #fff;
  --border-radius: 8px;
  --box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.147727);
  --min-height: 50px;
  --placeholder-color: var(--ion-color-primary);
  --icon-color: #8f8b99;
  height: 50px;
  padding: 0;
  margin-bottom: 15px;

  .searchbar-input-container.sc-ion-searchbar-ios {
    height: 50px;
  }

  ion-icon,
  .searchbar-search-icon.sc-ion-searchbar-md {
    width: 18px;
    height: 18px;
    top: 13px;
  }

  .searchbar-search-icon.sc-ion-searchbar-ios {
    left: 15px;
  }

  input,
  .searchbar-input.sc-ion-searchbar-md,
  .searchbar-input.sc-ion-searchbar-ios {
    font-size: 14px;
    color: $primary-color;
    border: 1px solid #{$rebrand-gray-100};
    padding-inline-start: 45px !important;
  }
}

/* Radio button */
ion-radio.lc-radio {
  min-width: 20px;
  --color: #ebe8f9;
  --color-checked: var(--ion-color-primary);
  --border-width: 10px;

  &.radio-checked {
    --border-width: 3px;
  }
}

/* Checkbox button */
ion-checkbox {
  --background: #{$rebrand-gray-100};
  --border-color: #{$rebrand-gray-100};
  --size: 22px;
}

/* Form Group */
.lc-form-group {
  position: relative;

  /* Error message */
  span {
    position: absolute;
    top: 78px;
    color: $danger-color;
    font-size: 12px;
    left: 10px;
  }

  ion-select {
    color: #242C39;
  }

  ion-datetime {
    color: #242C39;
  }

  .lc-input-password span {
    top: 57px;
  }

  /* error styles for label and input */
  &-error {

    ion-input,
    ion-select {

      input,
      .native-input.sc-ion-input-md,
      .native-input.sc-ion-input-ios {
        border: 1px solid $danger-color;
      }
    }

    ion-datetime,
    .lc-input {
      border: 1px solid $danger-color;
    }
  }

  // Disabled styles
  &.item-interactive-disabled {
    ion-label.label-stacked {
      opacity: 1;
    }

    ion-input {
      --background: #f2f2f2;
    }
  }
}

/* When there are 2 inputs one next to another one*/
.lc-form-col-2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ion-item {
    margin: 0 10px 0 0;
    width: 100%;

    +ion-item {
      margin: 0 0 0 10px;
    }
  }
}

/* Select list, not on from group */
.lc-select-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #c1bfc6;
  padding: 6px 15px;

  &:last-child {
    border-bottom: 1px solid #c1bfc6;
  }

  ion-select {
    font-size: 14px;
    color: $primary-color;
    max-width: 200px;
    min-height: 0;
    min-width: 200px;
    margin: 0;
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }

  ion-label {
    font-size: 14px;
    font-weight: 400;
  }

  ion-checkbox {
    margin: 12px;
  }
}

:focus-visible {
  outline: none;
}

.terms-error {
  color: $danger-color;
  font-size: 12px;
  left: 10px;
}