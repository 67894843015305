ion-card {
  box-shadow: none;
  width: 100%;
}

ion-card-header {
  height: 100px;
  background-image: url('../../assets/img/rebrand-cards/BG3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -50px;
}

.blank-card {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
}