@import '../../../../theme/colors.scss';

.flex-col {
  display: flex;
  flex-direction: column;
}

.rebrand-card {
  width: 100%;
  max-width: 400px;
  @extend .flex-col;
  cursor: pointer;

  div {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -65px;
    padding: 12px;
    height: 80px;

    span {
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 700;
      padding-top: 16px;
      padding-left: 8px;
      font-size: 24px;
      height: 100%;
      color: $rebrand-navy;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
  }

  &-date {
    @extend .flex-col;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background: $rebrand-navy;
    color: white;
    border-radius: 16px;
    font-size: 20px;
    height: fit-content;

    span {
      font-size: 18px;

      &:nth-child(2) {
        font-weight: bold;
      }
    }

    time {
      @extend .flex-col;
      align-items: center;
    }
  }

  &-content {
    display: flex;
    padding: 12px;
    gap: 16px;
    width: 100%;
  }

  &-text {
    @extend .flex-col;
    gap: 4px;

    ion-text {
      color: $rebrand-navy;
      font-weight: 700;
      letter-spacing: 0.75px;

      &:nth-child(1) {
        color: $rebrand-gray-200;
        text-transform: uppercase;
        font-size: 14px;
      }

      &:nth-child(2) {
        font-size: 16px;
      }

      &:nth-child(3) {
        font-weight: 400;
        font-size: 16px;
      }
    }

    ion-chip {
      width: fit-content;
      text-transform: capitalize;
      margin: 0px;
    }
  }

  &-arrow {
    display: grid;
    place-content: center;
    width: 32px;
    background: $rebrand-blue-100;
    font-size: 20px;
    color: $rebrand-navy;
  }

  &-chip {
    padding: 8px;
    height: 24px;
    color: white;
  }
}

.confirmed {
  background: #77D5A7;
}

.unconfirmed {
  background: #FFC833;
}