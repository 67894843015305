@import '../../../theme/colors.scss';

.lc-homepage {
  display: flex;
  align-items: center;
  justify-content: center;

  article {
    max-width: 400px;
  }

  >div {
    >section {
      >section:first-child {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    >div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      >section {
        display: flex;
        width: 100%;
        max-width: 1024px;
        height: 100%;
        column-gap: 16px;
        align-items: center;
        justify-content: center;

        >section {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          >* {
            width: 100%;
          }
        }
      }
    }
  }

  h2 {
    padding: 30px 0;
    font-weight: 700;
  }

  .lc-card-vertical-align {
    position: relative;

    +.lc-card-vertical-align {
      padding-top: 15px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 50px;
      background-color: #d8d1f5;
      height: 150px;
      width: 4px;
      z-index: -10;
    }

    &:last-child::after {
      position: absolute;
      bottom: -39px;
      left: 43px;
      background-size: 100%;
      content: '';
      background-image: url(./../../../assets/img/bg-card.png);
      background-color: inherit;
      height: 55px;
      width: 20px;
      background-repeat: no-repeat;
    }
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 16px;

  >ion-card {
    padding: 16px;
    width: 100%;
    max-width: 400px;

    ion-button {
      margin-top: 12px;
    }
  }
}