.lc-phone-register-buttons-container {
  margin: 0px 0px 10rem 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block-center-phone-register {
  max-width: 600px;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.register-phone-footer-container {
  max-width: 600px;
}
