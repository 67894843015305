@import './text.scss';

ion-card {
  border-radius: 25px;
  --ion-background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(101, 93, 198, 0.12), 0px 2px 24px rgba(101, 93, 198, 0.2);
  margin: 0;

  +ion-card {
    margin: 15px 0 0;
  }

  &.lc-pink-card {
    --ion-background-color: #fff9eb;

    ion-card-header {
      border-bottom: 1px solid #e0dfe4;
      font-size: 16px;
      font-weight: 700;
    }

    ion-card-content {

      h1,
      .card-content-md h1 {
        @extend h1;
        margin: 30px 0 15px 0;
      }

      h5 {
        font-weight: 700;
        margin-right: 15px;
      }
    }

    ion-item {
      --background: #ffedbd;

      ion-button {
        margin: 15px;
        width: 100%;
      }
    }
  }

  &.lc-card-home {
    justify-content: center;

    ion-card-content {
      padding: 15px 42px 15px 15px;
      display: flex;
      justify-content: flex-start;
      position: relative;
    }

    section {
      margin-left: 15px;
    }
  }

  &.rewards {
    --ion-background-color: #fff4d7;
  }

  &.viewed {
    --ion-background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

.viewed {
  --ion-background-color: transparent;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.card-done {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.section-todo {
  margin-top: 15px;
}

.card-content-md h2 {
  @extend h2;
}