@import '../../theme/rebrand.scss';
@import '../../theme/colors.scss';

.events-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.event_item-container {
  display: flex;
  cursor: pointer;
}

.event_inner-container {
  background: #{$rebrand-blue-100};
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 12px 12px;
}

.event_inner-container2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 12px 12px;
}

.event_title-for-you {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 16px;
  color: #{$rebrand-navy}
}

.event_first-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.event_date {
  font-size: 12px;
  font-weight: 700;
  color: #8F8B99;
  text-transform: uppercase;
}

.event_chip {
  padding: 8px;
  height: 24px;
  color: #{$rebrand-navy};
}

.confirmed {
  background: #{$rebrand-green-base};
}

.unconfirmed {
  background: #{$rebrand-yellow-base};
}

.event_info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event_info-title {
  font-size: 16px;
  font-weight: 700;
  color: #{$rebrand-navy};
}

.event_info-description {
  font-size: 14px;
  font-weight: 400;
  color: #{$rebrand-navy};

  em {
    font-style: normal;
    font-weight: 700;
  }
}

.event_card-button-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #{$rebrand-blue-500};
  width: 36px;
  color: #{$rebrand-navy};
  font-size: 24px;
}

.event-modal-container .modal-wrapper {
  border-radius: 32px;
  width: 100%;
  margin-left: 25%;
  margin-right: 25%;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.event-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  height: 16px;
  overflow: hidden;
  margin-top: 16px;
}

.event-empty-state {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin: 12px;
}

.event-list-modal {
  overflow-y: auto;
  height: 100%;
}