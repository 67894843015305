@import './../../../styles/text.scss';
@import './../../../theme/rebrand.scss';
@import './../../../theme/colors.scss';

.lc-care {
  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow: auto;
    padding: 32px 0 104px;
  }

  &-card-schedule {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
    max-width: 400px;

    ion-card-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      display: flex;
      background-image: url('../../../assets/img/rebrand-cards/BG4.jpg');
      background-position: center -75px;
      background-size: auto;
      background-repeat: no-repeat;
      padding-bottom: 0px;
      padding-top: 12px;
      height: 100px;
      justify-content: space-between;
      gap: 15%;
      padding-bottom: 16px;

      section {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    img {
      height: 130px;
    }

    >section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      gap: 8px;

      ion-text {
        font-size: 16px;
        line-height: 24px;
      }

      ion-button {
        font-size: 16px;
      }
    }
  }

  &-card-info {
    min-height: 280px;
    min-width: 320px;
    max-width: 400px;
    margin-bottom: 12px;


    ion-card-header {
      display: flex;
      background-image: url('../../../assets/img/rebrand-cards/BG1.jpg');
      background-position: -40px -75px;
      background-size: auto;
      background-repeat: no-repeat;
      padding-bottom: 0px;
      padding-top: 12px;
      height: 100px;
      justify-content: space-between;
      gap: 15%;
      padding-bottom: 16px;

      section {
        display: flex;
        flex-direction: column;
        gap: 8px;

        ion-text:first-child {
          color: #65616f;
          font-weight: 600;
          text-transform: uppercase;
        }

        ion-text:last-child {
          color: $primary-color;
          width: 60%;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    ion-card-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 16px;
      color: $text-dark;

      h3 {
        color: #{$rebrand-blue-800};
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 16px;
    padding: 8px 32px;
    background: transparent;

    @media only screen and (max-width: 500px) {
      padding: 8px 0px;
    }
  }

  &-family-item-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 0px;
    width: 100%;
    border-radius: 8px;

    img {
      height: 50px;
    }
  }

  &-family-item-data {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 4px;
    color: #{$rebrand-navy};

    span:last-child {
      text-transform: capitalize;
    }
  }


  &-add-member-title {
    color: $primary-color;
    font-weight: 600;
    display: flex;
  }

  &-add-member {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

}

.info-line {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;

  div {
    color: $tertiary-color;
    display: grid;
    place-content: center;
    background: $primary-color;
    border-radius: 50%;
    min-height: 36px;
    min-width: 36px;
    font-weight: 700;
  }
}

.add-family .modal-wrapper {
  position: absolute;
  top: 250px;
  border-radius: 16px;

  @media only screen and (min-width: 768px) {
    max-height: 500px;
  }
}

.container_first-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-navy);
  width: 100%;
  height: fit-content;
  padding-top: 64px;
  padding-inline: 16px;
  padding-bottom: 16px;

  @media only screen and (min-width: 1024px) {
    height: 100%;
    width: 40%;
  }
}

.card_get-connected-container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 400px;
  flex-direction: column;
  padding: 16px;
  gap: 12px;

  .title_get-connnected {
    font-size: 20px;
    font-weight: 700;
  }

  .title_get-description {
    font-size: 14px;
  }
}

.container_content {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  padding: 16px;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    height: 100%;
    width: 60%;
    justify-content: start;
    padding: 80px;
  }
}

.card_get-connected {
  min-height: 175px;
  margin: 0px;
  max-width: 400px;
}

.events-card {
  background: white;
  border-radius: 24px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 12px;

  box-shadow: 0px 2px 2px rgba(101, 93, 198, 0.12), 0px 2px 24px rgba(101, 93, 198, 0.2);
}