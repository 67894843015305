@import './../../../styles/text.scss';

.lc-earning {
  ion-button.button-small {
    max-width: 600px;
  }
  &-intro {
    margin: 0 -17px;
    padding: 30px 17px 10px;
  }
  h5 {
    margin: 0 0 20px;
  }

  &-card {
    margin: 10px -17px 0;
    padding: 10px 17px 20px;
    width: 448px;

    &-pending {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      h4 {
        margin: 20px 0 5px;
        color: #65616f;
      }

      p {
        margin: 0 0 17px;
      }
    }
  }

  &-total {
    width: 435px;
    h4,
    p {
      margin: 0;
    }

    p {
      line-height: 22px;
    }
  }

  &-error {
    ion-icon {
      width: 27px;
      height: 27px;
    }
  }

  &-confirmation {
    width: 100%;
    --padding-top: 0px;
  }
}

.cash-out-disabled {
  opacity: 0.25 !important;
}

.stripe-button {
  ion-icon {
    width: 110px;
    height: 33px;
  }
}

.paypal-button {
  ion-icon {
    width: 110px;
  }
}

.banner-link-account-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 600px) {
    margin-right: auto;
    margin-left: auto;
  }
}

.paypal-link-confirmation {
  align-items: center;
  justify-content: center;
  text-align: center;
  ion-icon {
    width: 115px;
    height: 35px;
  }
}

.stripe-link-confirmation {
  align-items: center;
  justify-content: center;
  text-align: center;
  ion-icon {
    width: 115px;
    height: 35px;
  }
}

.stripe-paypal-popup {
  ion-icon {
    margin-left: 5px;
  }
}

.account-info-container-paypal {
  text-align: center;
  justify-content: center;
  display: flex;
  margin-left: 95px;
  .edit-account-button {
    height: 22px;
    width: 27px;
  }
}

.account-info-container-stripe {
  text-align: center;
  justify-content: center;
  display: flex;
  margin-left: 100px;
  .edit-account-button {
    height: 22px;
    width: 27px;
  }
}
.block-center-earning-page {
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
