@import '../../../../../theme/colors.scss';

.bp {
  display: flex;
  width: 100%;

  hr {
    width: 100%;
    text-align: center;
    background: #232323;
    mix-blend-mode: color-burn;
    opacity: 0.5;
  }

  >section {
    padding: 16px;
  }

  >section:last-child {
    padding-left: 0px;
  }

  &-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    background: #{$rebrand-navy};
    color: white;
    gap: 4px;

    >span:nth-child(1) {
      font-size: 18px;
    }

    >span:nth-child(2) {
      font-weight: 700;
      font-size: 20px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 3;
    color: #{$rebrand-navy};

    >section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;

      >span:nth-child(1) {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  &-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #232323;
    opacity: 0.75;
    mix-blend-mode: color-burn;
  }

  &-ht-crisis {
    mix-blend-mode: normal;
    opacity: 1;
    color: white;
  }
}