.lc-footer-styles {
  background: #ffffff;
  box-shadow: 1px 1px 15px rgba(101, 93, 198, 0.25);
  border-radius: 34px 34px 0px 0px;
  padding: 15px;
}

ion-footer,
.lc-footer {
  @extend .lc-footer-styles;

  &.footer-md::before {
    background-image: none;
  }
}

.lc-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
}

.lc-footer-container {
  display: flex;
  flex-direction: row;
  gap: 30px
}

.lc-footer-container {
  .toast-wrapper {
    .toast-top {
      border-radius: 30px !important;
    }
  }
}
