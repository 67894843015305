@import '../theme/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');



:root {
  // REBRANDING VARIABLES >>>
  --ion-color-green: #55CD77;
  --ion-color-green-rgb: 85, 205, 119;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0, 0, 0;
  --ion-color-green-shade: #4bb469;
  --ion-color-green-tint: #66d285;

  --ion-color-yellow: #FFC84A;
  --ion-color-yellow-rgb: 255, 200, 74;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #e0b041;
  --ion-color-yellow-tint: #ffce5c;

  --ion-color-purple: #DAB6FF;
  --ion-color-purple-rgb: 218, 182, 255;
  --ion-color-purple-contrast: #000000;
  --ion-color-purple-contrast-rgb: 0, 0, 0;
  --ion-color-purple-shade: #c0a0e0;
  --ion-color-purple-tint: #debdff;

  --ion-color-blue: #B6E4FB;
  --ion-color-blue-rgb: 182, 228, 251;
  --ion-color-blue-contrast: #000000;
  --ion-color-blue-contrast-rgb: 0, 0, 0;
  --ion-color-blue-shade: #a0c9dd;
  --ion-color-blue-tint: #bde7fb;

  --ion-color-navy: #242C39;
  --ion-color-navy-rgb: 36, 44, 57;
  --ion-color-navy-contrast: #ffffff;
  --ion-color-navy-contrast-rgb: 255, 255, 255;
  --ion-color-navy-shade: #202732;
  --ion-color-navy-tint: #3a414d;

  // REBRANDING VARIABLES <<<

  .theme {
    p {
      color: #{$rebrand-navy};
    }

    ion-text {
      color: #{$rebrand-navy};
    }

    ion-button {
      --ion-color-primary: #{$rebrand-blue-500};
      color: #{$rebrand-navy};
    }

    ion-button[disabled='false'] {
      >ion-icon {
        color: #{$rebrand-navy};
      }
    }

    ion-checkbox {
      --background: #{$rebrand-purple-100};
      --border-color: #{$rebrand-purple-100};
    }

    ion-radio {
      --color: #{$rebrand-purple-100};
      --border-color: #{$rebrand-purple-100};
    }

    // styles to change modal dependant
    // components, such as ion-select
    ion-alert {
      .alert-wrapper {
        .alert-button-group {
          .alert-button:nth-child(2) {
            background: #{$rebrand-blue-500};
            color: #{$rebrand-navy};
          }
        }
      }
    }

    --ion-color-primary: #{$rebrand-navy};

    h3 {
      color: #{$rebrand-blue-800};
    }

    ion-button[fill="outline"] {
      --ion-color-primary: var(--ion-color-navy);
    }

    /** text **/
    --ion-font-family: 'Raleway',
    sans-serif;
    --ion-text-color: --ion-color-primary;
    --ion-text-color-rgb: --ion-color-primary-rgb;

    /** Grid **/
    --ion-grid-padding-xs: 0;
    --ion-grid-padding-sm: 0;
    --ion-grid-padding-md: 0;
    --ion-grid-padding-lg: 0;
    --ion-grid-padding-xl: 0;

    /** Backdrop **/
    --ion-backdrop-color: rgba(0, 0, 0, 0.3);
  }
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-navy {
  --ion-color-base: var(--ion-color-navy);
  --ion-color-base-rgb: var(--ion-color-navy-rgb);
  --ion-color-contrast: var(--ion-color-purple);
  --ion-color-contrast-rgb: var(--ion-color-navy-contrast-rgb);
  --ion-color-shade: var(--ion-color-navy-shade);
  --ion-color-tint: var(--ion-color-navy-tint);
}

$primary-color: #242C39;
$primary-color: #242C39;
$tertiary-color: #eeedf9;
$medium-dark-color: #c1bfc6;
$danger-color: #f06956;
$success-color: #77d4a7;
$warning-color: #ffc833;
$lch-orange: #FF8930;
$input-background-disabled: #ffffff80;
$input-text-disabled: #8a7db1;
$text-dark: #040031;
$warning-light-color: #fff4d7;