.lch-error-container {
  height: 250px;
  width: 262px;
  background-image: url('../../assets/img/error.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 110px;
}

.lch-error-footer {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 15px;
}

.lch-error-redirect-button {
  --border-color: #230870;
  --color: #230870;
  --border-style: solid;
  --border-width: 1px;
  box-sizing: border-box;
  --border-radius: 20px;
  --background: transparent;
  --background-activated: transparent;
}

.error-code {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding-top: 4.5em;
  padding-left: 0.5em;
  h6 {
    color: #e1d6fe;
  }
}

.error-title {
  h6 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: white;
    padding-top: 1.8em;
    padding-left: 0.5em;
  }
}

.error-message {
  h6 {
    color: #65616f;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    padding-top: 30px;
    padding-left: 0.5em;
  }
}

.lc-journey-container {
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.lc-error-page-buttons-container {
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
.block-center-error-page {
  max-width: 600px;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}
