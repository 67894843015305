@import '../../../../theme/colors.scss';

.lc-slider {
  &-wrapper {
    padding-top: 30px;
    height: calc(var(--vh, 1vh) * 100 - 152px);
    padding-bottom: 15px;
    overflow-y: auto;

    .lc-form-group {
      padding: 10px 17px 0;
    }
  }

  &-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #{$rebrand-purple-50};
    padding: 15px 20px;
    margin: 0 17px 10px;
    border-radius: 15px;
    min-height: 56px;
    cursor: pointer;

    ion-label {
      font-size: 16px;
      line-height: 18px;
      text-align: left;
      margin-left: 10px;

      &.lc-slider-label-selected {
        font-weight: 700;
      }
    }
  }

  &-radio-selected {
    background: #{$rebrand-purple-100};
  }

  &-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #{$rebrand-purple-50};
    padding: 15px 20px;
    margin: 0 17px 10px;
    border-radius: 15px;
    min-height: 56px;

    ion-label {
      font-size: 16px;
      line-height: 18px;
      text-align: left;
      margin-left: 10px;

      &.lc-slider-label-selected {
        font-weight: 700;
      }
    }
  }

  &-confirmation {
    width: 100%;

    article {
      padding: 30px 17px 100px;
      color: #fff;
      width: 100%;

      h4 {
        font-weight: 700;
      }
    }

    ion-card {
      border-radius: 15px;
      padding: 40px 20px 20px;
      margin: -41px 15px 0 15px;
      z-index: 1;
    }

    &-coin {
      position: absolute;
      top: 170px;
      left: 0;
      right: 0;

      span {
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #ffc833;
        border: 1px solid #fff;
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        line-height: 50px;
        z-index: 2;
        margin: 0 auto;

        &:before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          width: 50px;
          height: 50px;
          z-index: 1;
          border: 2px solid #ffc833;
          border-radius: 50%;
        }
      }
    }

    ion-button {
      width: 100%;
    }

    ion-icon {
      font-size: 20px;
      margin: 10px 0;
    }

    footer {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.block-center-slider-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}