@import '../../theme/colors.scss';

.lc-register {
  section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100%;

    +ion-item {
      flex-grow: 1;
    }
  }

  article {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 2;
    margin-top: 16px;
  }

  &-terms-link {
    margin-bottom: 5px;
    font-size: 14px;
  }

  &-headline {
    margin-top: 20px;
    font-size: 32px;
    font-weight: 600;

    @media only screen and (min-width: 500px) {
      margin-top: 60px;

    }
  }

  &-logo {
    margin-top: 20px;
  }

  &-footer-asset {
    width: 100vw;
    margin-top: 40px;
    margin-left: -20px;

    img {
      width: 100%;
    }

    @media only screen and (min-width: 500px) {
      display: none;
    }
  }
}

.lc-login {
  &-with {
    position: relative;
    width: 100%;
    padding: 30px 0px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }

    span {
      background-color: white;
      padding-inline: 20px;
    }

    btn {
      background: $rebrand-blue-500;
    }
  }
}