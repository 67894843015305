.external-modal {
  color: red;
}

.custom-modal .modal-wrapper {
  width: 90%;
  height: 90%;
  border-radius: 20px;
}

.findHelp-iframe {
  width: 100%;
  height: 100%;
}

.lc-modal-header-findhelp {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;

  h3 {
    margin: 0px;
  }

  ion-button.button-small {
    width: auto;
    padding: 8px 0px;
  }
}