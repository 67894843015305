.illustration-container {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
}

.title-container {
  margin-top: 10px;

  h4 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #230870;
  }
}

.sub-title-container {
  text-align: center;
  margin-bottom: 10px;

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #230870;
  }
}

.lch-edit-number-button {
  --border-color: #230870;
  --color: #230870;
  --border-style: solid;
  --border-width: 1px;
  box-sizing: border-box;
  --border-radius: 20px;
  --background: transparent;
  --background-activated: transparent;
}

.lch-send-verification-code-button {
  --box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.147727);
}

.buttons-container {
  max-width: 820px;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.content-container {
  background-color: #eeedf9;
}

.lc-cellphone-verification-buttons-container {
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.block-center-cellphone-verification {
  max-width: 600px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 600px) and (max-width: 800px) {
    margin-left: 192px;
  }

  @media only screen and (min-width: 1024px) {
    margin-left: 325px;
  }

  @media only screen and (min-width: 1200px) {
    margin-left: 407px;
  }

  @media only screen and (min-width: 1280px) {
    margin-left: 447px;
  }

  @media only screen and (min-width: 1980px) {
    margin-left: 801px;
  }
}